import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import userHelper from "../helpers/userHelper";
import dataHelper from "../helpers/dataHelper";
import InteractiveWorkout from "./parts/InteractiveWorkout";
import _ from "lodash";
import Loading from "./Loading";
import Timer from "./parts/Timer";

const PortalWorkoutTracker = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [accessLevel, setAccessLevel] = useState(0);
  const [interactiveLoader, setInteractiveLoader] = useState(true);
  const [workoutPlan, setWorkoutPlan] = useState({});
  const [workoutList, setWorkoutList] = useState({});
  const [selectedDay, setSelectedDay] = useState();
  const [selectedWorkout, setSelectedWorkout] = useState("");
  const [workoutNotes, setWorkoutNotes] = useState("");

  const nav = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    try {
      let select = document.getElementById("selectedWorkout");
      select.value = selectedWorkout;
    } catch (err) {
      return;
    }
  }, [loaded]);

  const fetchData = async () => {
    const uman = new userHelper();
    const dman = new dataHelper();
    const response = await uman.getClientData();
    const result = await response.json();
    const response2 = await dman.getCurrentWorkoutPlan();
    const result2 = await response2.json();
    const response3 = await dman.getWorkoutList();
    const result3 = await response3.json();

    if (!result.data) {
      nav(`/login`);
    }
    const { data } = result;
    const { level } = data;
    setCurrentUser(data);
    setAccessLevel(level);
    console.log('setWorkoutPlan', result2.data);
    setWorkoutPlan(result2.data);
    setWorkoutList(result3.data);
    setLoaded(true);
  };

  const switchDay = (day) => {
    let wp;
    setInteractiveLoader(true);
    switch (day) {
      case "monday":
        wp = JSON.parse(workoutPlan.monday);
        setSelectedDay(wp);
        break;
      case "tuesday":
        wp = JSON.parse(workoutPlan.tuesday);
        setSelectedDay(wp);
        break;
      case "wednesday":
        wp = JSON.parse(workoutPlan.wednesday);
        setSelectedDay(wp);
        break;
      case "thursday":
        wp = JSON.parse(workoutPlan.thursday);
        setSelectedDay(wp);
        break;
      case "friday":
        wp = JSON.parse(workoutPlan.friday);
        setSelectedDay(wp);
        break;
      case "saturday":
        wp = JSON.parse(workoutPlan.saturday);
      console.log('happy saturday', wp);
        setSelectedDay(wp);
        break;
      case "sunday":

        wp = JSON.parse(workoutPlan.sunday);
        setSelectedDay(wp);
        break;
    }
    setTimeout(() => {
      setInteractiveLoader(false);
    }, 100);
  };

  const populateWorkoutSelect = () => {
    let payload = [];
    workoutList.forEach((workout) => {
      payload.push(genTag(workout._id, workout.name));
    });
    return payload;
  };

  const genTag = (id, name) => {
    return <option value={id}>{name}</option>;
  };

  const handleManualNoteSelect = async () => {
    let workout = document.getElementById(`selectedWorkout`).value;
    setLoaded(false);
    setSelectedWorkout(workout);
    await refreshNotes(workout);
    setLoaded(true);
  };

  const refreshNotes = async (target) => {
    const dman = new dataHelper();
    const response = await dman.getCurrentNotes(target);
    const result = await response.json();
    setWorkoutNotes(result.data);
  };

  const saveNotes = _.debounce(async () => {
    await handleSaveNotes();
  }, 750);

  const handleInputChange = (value) => {
    saveNotes();
  };

  const handleSaveNotes = async () => {
    const notes = document.getElementById("manualNotes").value;
    setLoaded(false);
    const dman = new dataHelper();
    const response = await dman.saveWorkoutNotes(selectedWorkout, notes);
    const result = await response.json();
    await refreshNotes(selectedWorkout);
    setLoaded(true);
  };

  const renderInteractiveWorkout = () => {
    return (
      <InteractiveWorkout
        selectedDay={selectedDay}
        activeWorkouts={selectedDay.workouts}
        workoutList={workoutList}
      />
    );
  };

  if (loaded) {
    return (
      <div className="portalWorkoutTracker">
        <h2>Workout Tracker</h2>
        <p>
          Remove the guesswork by recording your weights, reps, and rest here in
          the workout tracker.
        </p>
        <p>
          To begin an interactive workout, select your workout from the list
          below. As you perform each exercise, record your information and then
          move on to the next.
        </p>
        <p>
          If you want to take notes for an exercise not a part of your training
          plan, you may do so below in the Records section.
        </p>
        <select
          id="selectedDay"
          onChange={(e) => {
            switchDay(e.target.value);
          }}
        >
          <option value="">SELECT A WORKOUT</option>
          <option value="monday">Monday</option>
          <option value="tuesday">Tuesday</option>
          <option value="wednesday">Wednesday</option>
          <option value="thursday">Thursday</option>
          <option value="friday">Friday</option>
          <option value="saturday">Saturday</option>
          <option value="sunday">Sunday</option>
        </select>
        {interactiveLoader ? null : renderInteractiveWorkout()}
        <h2>Records</h2>
        <p>
          Select a workout below to view all previous records for any exercise,
          and to manually add notes.
        </p>
        <select
          id="selectedWorkout"
          name="selectedWorkout"
          onChange={async () => {
            await handleManualNoteSelect();
          }}
        >
          <option value="">SELECT A WORKOUT</option>
          {populateWorkoutSelect()}
        </select>
        {selectedWorkout ? (
          <>
            <h3>Note Entry:</h3>
            <textarea
              name="manualNotes"
              id="manualNotes"
              placeholder="Record your notes here!"
              onChange={(e) => {
                handleInputChange(e.target.value);
              }}
            >
              {workoutNotes}
            </textarea>
          </>
        ) : null}
        <button
          onClick={async () => {
            await handleSaveNotes();
          }}
        >
          Save Notes
        </button>
      </div>
    );
  } else return <Loading />;
};

export default PortalWorkoutTracker;
